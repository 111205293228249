.body {
  margin: 0;
  padding: 0;
  font-family: futura-pt, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  --isDarkMode:false;
}

@media (prefers-color-scheme: dark) {
  .body {
    margin: 0;
    padding: 0;
    font-family: futura-pt, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #202124;
    --isDarkMode:true;
  }
}

.animatedFadeIn {
  -webkit-animation: fadein .25s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein .25s; /* Firefox < 16 */
      -ms-animation: fadein .25s; /* Internet Explorer */
        -o-animation: fadein .25s; /* Opera < 12.1 */
          animation: fadein .75s;
}

.animatedFastFadeIn {
  -webkit-animation: fadein .25s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein .25s; /* Firefox < 16 */
      -ms-animation: fadein .25s; /* Internet Explorer */
        -o-animation: fadein .25s; /* Opera < 12.1 */
          animation: fadein .25s;
}


@keyframes fadein {
    from { opacity: 0.05; }
    to   { opacity: 1; }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}